import css from "@emotion/css"
import { Section } from "bloomer"
import debug from "debug"
import PropTypes from "prop-types"
import React from "react"

import NewsLetterBlock from "../blocks/newsletter.block"
import { GalleryCarousel } from "./gallery-carousel.component"
import { LatestNews } from "./latest-news.component"
import { ModelsCarousel } from "./model-carousel.component"
import { PageSection } from "./page-section.component"
import { parseTemplate } from "./page-template.component"

export const isCategoryTemplate = name =>
  name === `page-template-travel` || name === `page-template-girlfriend`

const PageCategoryFields = props => {
  const { page, posts, models, models_gf } = props.data
  const { acf, template } = page
  const templateName = parseTemplate(template)
  const { category_gallery } = acf
  debug(`PageCategoryFields`)(props)
  const postsData = posts && posts.edges.map(e => e.node)
  const modelsData = templateName === `page-template-travel` ? models : models_gf
  return isCategoryTemplate(templateName) ? (
    <>
      <Section isPaddingless>
        <GalleryCarousel slides={category_gallery} responsiveStyle={`fixed`} height={300} />
      </Section>
      <PageSection>
        <ModelsCarousel
          models={modelsData.edges.map(({ node }) => node)}
          carouselStyle={
            (templateName === `page-template-travel` ? `category` : ``) +
            (templateName === `page-template-girlfriend` ? `centered` : ``)
          }
        />
      </PageSection>
      <Section
        css={css`
          padding: 5rem 0;
        `}
      >
        <NewsLetterBlock />
      </Section>
      {postsData && <LatestNews posts={postsData} />}}
    </>
  ) : null
}

PageCategoryFields.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

export default PageCategoryFields
