/* eslint-disable camelcase */
import css from "@emotion/css"
import styled from "@emotion/styled"
import { Content, Title } from "bloomer"
import { useTheme } from "emotion-theming"
import hexRgb from "hex-rgb"
import React from "react"

import { THEMES } from "../config/theme"
import { YELLOW } from "../consts/colors.constants"
import { parseTitle } from "../utils/text.utils"
import { themedBox } from "../utils/theme.utils"
import { ButtonShaded } from "./button-shaded.atom"

export const rgba = (hex, alpha) => {
  const rgb = hexRgb(hex, { format: `array` })
    .slice(0, -1)
    .join(`,`)
  return `${rgb},${alpha}`
}

export const introBox = ({ themeStyle, transparency }) => css`
  background-color: ${themeStyle === `dark`
    ? `rgba(17, 17, 17, ${transparency});`
    : `rgba(255, 255, 255, ${transparency});`};
`

export const StyledIntroSection = styled.div`
  position: relative;
  z-index: 300;
  transform: translate3d(0, -4rem, 0);
  height: 0;
  display: flex;
  a {
    color: ${YELLOW};
  }
`

export const StyledIntroBox = styled.div`
  ${themedBox};
  ${introBox};
  width: 100%;
  @media screen and (min-width: 468px) {
    align-self: flex-end;
    max-width: 25%;
    left: 50%;
    bottom: ${props => props.offset}px;
  }
  @media screen and (min-width: 768px) {
    width: 33%;
    max-width: 470px;
    left: 50%;
  }
  padding: 3rem;
  a,
  strong {
    color: ${YELLOW};
    &: hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`

export const PageIntro = ({
  title,
  transparency,
  text,
  button_text,
  button_link,
  offset,
  theme_style,
}) => {
  const themeGlobal = useTheme()
  const theme = THEMES[theme_style] || themeGlobal
  return (
    <StyledIntroSection>
      <StyledIntroBox
        offset={offset || 0}
        className={`is-radiusless page-intro__box theme-${theme.name}`}
        transparency={transparency ? transparency / 100 : 0}
        themeStyle={theme.name}
      >
        {title && (
          <Title
            className="page-intro--title"
            dangerouslySetInnerHTML={{
              __html: parseTitle(title),
            }}
          />
        )}
        <Content
          className="page-intro--content"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {button_text && (
          <ButtonShaded isLink={true} href={button_link}>
            {button_text}
          </ButtonShaded>
        )}
      </StyledIntroBox>
    </StyledIntroSection>
  )
}
