import { Column, Columns, Container } from "bloomer"
import * as React from "react"
import { Newsletter } from "../components/newsletter.component"

const NewsLetterBlock = props => (
  <Container>
    <Columns isCentered>
      <Column isSize={`full`} hasTextAlign={`left`}>
        <Newsletter />
      </Column>
    </Columns>
  </Container>
)

export default NewsLetterBlock
