import styled from "@emotion/styled"
import { Section, Title } from "bloomer"
import { Container } from "bloomer/lib/layout/Container"
import PropTypes from "prop-types"
import React from "react"
import NewsLetterBlock from "../blocks/newsletter.block"
import PostsList from "./posts-list.component"
import { Link } from "gatsby"
import { Box } from "bloomer/lib/elements/Box"
import css from "@emotion/css"

export const parseTemplate = filename => (filename ? filename.replace(`.php`, ``) : ``)

export const NewsTitle = styled(Title)`
  font-family: Georgia, "Times New Roman", Times, serif;
  	font-size: 36px;	font-weight: bold;	letter-spacing: 0.9px;	line-height: 58px;	text-align: center;}
`
const NewsTemplate = props => {
  const { posts } = props.data
  const firstPost = posts.edges[0].node
  return (
    <>
      <Section
        css={css`
          margin-top: -5rem;
          z-index: 4;
          position: relative;
        `}
        isPaddingless
      >
        <Container>
          <Box
            css={css`
              text-align: center;
            `}
          >
            <time>{firstPost.date}</time>
            <NewsTitle
              css={css`
            max-width: width: 767px;`}
            >
              {firstPost.title}
            </NewsTitle>
            <Link to={firstPost.path}>Read Article</Link>
          </Box>
        </Container>
      </Section>
      <Section>
        <Container>
          <NewsLetterBlock />
        </Container>
      </Section>
      <Section isPaddingless>
        <Container>
          <PostsList posts={posts.edges.slice(1, posts.edges.length).map(({ node }) => node)} />
        </Container>
      </Section>
    </>
  )
}
NewsTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default NewsTemplate
