import css from "@emotion/css"
import {
  Card,
  CardContent,
  CardImage,
  Column,
  Columns,
  Container,
  Content,
  Section,
  Title,
} from "bloomer"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"

import ImageWrap from "../atoms/image-wrap.atom"
import { DARK } from "../consts/colors.constants"

export const LatestNews = ({ posts }) => {
  const numItems = 12
  const [currentPage, setCurrentPage] = useState(0)
  useEffect(() => {
    setCurrentPage(0)
  }, [])

  return (
    <Section>
      <Container>
        <Title
          isSize={2}
          hasTextAlign={`centered`}
          css={css`
            margin-bottom: 3rem;
          `}
        >
          Related News
        </Title>
        <Columns
          css={css`
            margin-top: 3rem;
          `}
          isMultiline
        >
          {posts &&
            posts.slice(currentPage, numItems).map(post => {
              const { excerpt, featured_media, date, id, title, slug } = post
              return (
                <Column isSize={`1/3`} key={id}>
                  <Link to={`/${slug}`}>
                    <Card
                      css={css`
                        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
                          0 0px 0 0.1em rgba(10, 10, 10, 0.02);
                      `}
                    >
                      <CardImage isClearfix>
                        <ImageWrap
                          image={featured_media}
                          fadeIn={true}
                          css={css`
                            overflow: hidden;
                            max-width: 100%;
                          `}
                          placeholderStyle={{
                            backgroundColor: `black`,
                          }}
                        />
                      </CardImage>
                      <CardContent>
                        <Title
                          as={`h2`}
                          css={css`
                            color: ${DARK} !important;
                          `}
                          isSize={4}
                        >
                          {title}
                        </Title>
                        <Content>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: excerpt,
                            }}
                          />
                        </Content>
                        <small>{date}</small>
                      </CardContent>
                    </Card>
                  </Link>
                </Column>
              )
            })}
        </Columns>
      </Container>
    </Section>
  )
}
