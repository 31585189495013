import { Container, Section } from "bloomer"
import { Box } from "bloomer"
import debug from "debug"
import queryString from "query-string"
import React from "react"
import useSWR from "swr"

const withPreviewRest = (args = { endpoint: `/pages` }) => Component => {
  const preview = props => {
    const parsed = queryString.parse(props.location.search)
    const { nonce, preview, post } = parsed

    debug(`withPreviewRest`)(nonce)
    // Id needs to be an int for preview query.
    const id = parseInt(post, 10)

    /**
     * If no preview param, return the component with the preview props as false.
     */
    if (!preview) {
      return <Component preview={false} {...props} />
    }

    const fetcher = url =>
      fetch(url, {
        headers: {
          // "X-WP-Nonce": nonce ? nonce : ``,
        },
      }).then(r => r.json())

    const reqUrl =
      `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_DOMAIN}/wp-json/wp/v2` +
      args.endpoint +
      `/` +
      id +
      `?nonce=` +
      nonce

    console.log(reqUrl)
    const { data, error } = useSWR(reqUrl, fetcher)

    if (error)
      return (
        <Section>
          <Container></Container>
        </Section>
      )
    if (!data)
      return (
        <Section>
          <Container>
            <Box>loading...</Box>
          </Container>
        </Section>
      )
    return <Component preview={data} {...props} />
  }

  return preview
}

export default withPreviewRest
