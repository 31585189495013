import css from "@emotion/css"
import { Card, Column, Columns, Title } from "bloomer"
import { CardContent } from "bloomer/lib/components/Card/CardContent"
import { CardImage } from "bloomer/lib/components/Card/CardImage"
import { graphql } from "gatsby"
import React from "react"

import ImageWrap from "../atoms/image-wrap.atom"
import { DARK } from "../consts/colors.constants"

const PostsList = ({ posts }) => (
  <Columns isMultiline className="posts-list">
    {posts.map(node => {
      const post = node
      return (
        <Column isSize={4} key={node.id}>
          <a href={`/${post.slug}`}>
            <Card>
              {post.featured_media && (
                <CardImage>
                  <ImageWrap
                    responsiveStyle={`fixed`}
                    image={post.featured_media}
                    fadeIn={true}
                    css={css`
                      overflow: hidden;
                      max-width: 100%;
                    `}
                    placeholderStyle={{
                      backgroundColor: `black`,
                    }}
                  />
                </CardImage>
              )}
              <CardContent key={post.slug}>
                <time>{post.date}</time>
                <Title
                  tag={`h2`}
                  css={css`
                    font-family: Georgia, "Times New Roman", Times, serif;
                    font-weight: 400;
                    color: ${DARK} !important;
                    font-size: 24px;
                    letter-spacing: 0.6px;
                    line-height: 30px;
                  `}
                >
                  {post.title}
                </Title>
                <div dangerouslySetInnerHTML={{ __html: post && post.excerpt }} />
              </CardContent>
            </Card>
          </a>
        </Column>
      )
    })}
  </Columns>
)
export default PostsList

export const postsListQuery = graphql`
  fragment WPPostListFields on wordpress__POST {
    ...WPPostFields
    featured_media {
      source_url
      localFile {
        ...ModelGalleryImage
      }
    }
  }
`
