/* eslint-disable camelcase */

import React from "react"
import { WPGBlock } from "react-gutenberg"
import GetCustomBlock from "../blocks"
import { ContentSection } from "./content-section.component"

const PageContent = ({ content_sections, blocks }) =>
  blocks
    ? blocks.map((block, i) =>
        block.blockName === `cgb/block-topco-block` ? (
          <ContentSection key={`${block.blockName}/${i}`} content_sections={content_sections} />
        ) : (
          <WPGBlock
            key={`${block.blockName}/${i}`}
            block={block}
            content_sections={content_sections}
            mapToBlock={GetCustomBlock}
          />
        )
      )
    : null
export default PageContent
