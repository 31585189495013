import css from "@emotion/css"
import { Button } from "bloomer"
import React from "react"

export const ButtonShaded = props => (
  <Button
    className="is-radiusless has-shade"
    isColor={`primary`}
    isLink
    css={css`
      margin-top: 1rem;
      padding: 0 2rem;
    `}
  >
    <div className="text">{props.children}</div>
  </Button>
)
