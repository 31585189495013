import debug from "debug"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import PageTemplate from "../components/page-template.component"
import withPreviewRest from "../components/withPreviewRest"

/* eslint-disable camelcase */
const Page = props => {
  const postData = props.preview ? props.preview : props.data.page
  debug(`Page`)(postData)
  const title = postData.title.rendered || postData.title
  return <PageTemplate title={title} {...props} />
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export const Responsive = graphql`
  fragment ResponsiveImageSquare on File {
    childImageSharp {
      fluid(maxWidth: 500, maxHeight: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment ResponsiveImageHD on File {
    childImageSharp {
      fluid(maxWidth: 2560, maxHeight: 1600) {
        ...GatsbyImageSharpFluid_withWebp
      }
      fixed(height: 1220) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  fragment ResponsiveContentImageSquare on File {
    childImageSharp {
      fluid(maxWidth: 945, maxHeight: 600) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }

  fragment ModelProfileImage on File {
    childImageSharp {
      fixed(height: 767) {
        ...GatsbyImageSharpFixed_withWebp
      }
      fluid(maxWidth: 1224) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment ModelProfileImageMain on File {
    childImageSharp {
      fixed(height: 768) {
        ...GatsbyImageSharpFixed_withWebp
      }
      fluid(maxHeight: 768) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment ModelGalleryImageSmall on File {
    childImageSharp {
      fixed(height: 300) {
        ...GatsbyImageSharpFixed_withWebp_tracedSVG
      }
    }
  }
  fragment PostListimage on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }

  fragment ModelGalleryImage on File {
    childImageSharp {
      fixed(height: 514) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  fragment ImageFields on wordpress__wp_media {
    id
    source_url
  }
`
export const pageQuery = graphql`
  fragment ContentSections on wordpress__PAGEAcfCustomContent_sections {
    orientation
    text
    style
    content_section_spacing_top
    content_section_spacing_bottom
    vixy_video
    section_image {
      ...ImageFields
      localFile {
        ...ResponsiveContentImageSquare
      }
    }
  }
  fragment PageAcfOptions on wordpress__PAGEAcfCustom {
    category_gallery {
      ...ImageFields
      localFile {
        ...ModelGalleryImageSmall
      }
      acf {
        gallery_image_url {
          target
          title
          url
        }
      }
    }
    content_sections {
      ...ContentSections
    }
    page_category {
      term_id
      slug
    }
    page_intro_button_link
    page_intro_button_text
    page_intro_show
    page_intro_text
    page_intro_transparency
    page_intro_theme
    page_slider_images_background_position {
      image {
        id
        ...ImageFields
        localFile {
          ...ResponsiveImageHD
        }
      }
      image_align
    }
    page_slider_images {
      id
      ...ImageFields
      localFile {
        ...ResponsiveImageHD
      }
    }
    page_sloped
    page_theme
    slideshow_height
  }

  fragment YoastFields on wordpress__PAGE {
    yoast {
      canonical
      focuskw
      linkdex
      meta_robots_adv
      meta_robots_nofollow
      meta_robots_noindex
      metadesc
      metakeywords
      opengraph_description
      opengraph_image
      opengraph_title
      redirect
      title
      twitter_description
      twitter_image
      twitter_title
    }
  }

  fragment WPPage on wordpress__PAGE {
    acf {
      ...PageAcfOptions
    }
    ...YoastFields
    blocks {
      ...Blocks
    }
    date
    excerpt
    id
    slug
    title
    type
    template
    wordpress_id
  }

  query GET_PAGE($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      ...WPPage
    }

    models: allWordpressWpModel {
      edges {
        node {
          ...ModelCarouselCategoryFields
        }
      }
    }

    models_gf: allWordpressWpModel {
      edges {
        node {
          ...ModelListFields
        }
      }
    }

    latestPost: allWordpressPost(limit: 1) {
      edges {
        node {
          ...WPPostFields
          featured_media {
            id
            source_url
            localFile {
              ...ResponsiveImageHD
            }
          }
        }
      }
    }
    posts: allWordpressPost(limit: 10) {
      edges {
        node {
          ...WPPostFields
          featured_media {
            id
            source_url
            localFile {
              ...ModelGalleryImage
            }
          }
        }
      }
    }
  }
`

export default withPreviewRest({ endpoint: `/pages` })(Page)
