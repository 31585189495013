import loadable from "@loadable/component"

const ParagraphBlock = loadable(() => import(`./paragraph-custom.block`))
const ButtonBlock = loadable(() => import(`./button-custom.block`))
const ContentBlock = loadable(() => import(`./content.block`))
const ContentAcfBlock = loadable(() => import(`./content-acf.block`))
const CoverBlock = loadable(() => import(`./cover-custom.block`))
const LatestNewsBlock = loadable(() => import(`./latest-news.block`))
const ModelsCarouselBlock = loadable(() => import(`./models-carousel.block`))
const NewsLetterBlock = loadable(() => import(`./newsletter.block`))
const VixyBlock = loadable(() => import(`./vixy-custom.block`))

const GetCustomBlock = (name, content_sections) => {
  if (name === `cgb/block-topco-block`) {
    return ContentBlock
  }

  switch (name) {
    case `core/paragraph`:
      return ParagraphBlock
    case `core/button`:
      return ButtonBlock
    case `core/cover`:
      return CoverBlock
    case `lazyblock/latest-news`:
      return LatestNewsBlock
    case `lazyblock/carousel-models`:
      return ModelsCarouselBlock
    case `lazyblock/newsletters`:
      return NewsLetterBlock
    case `lazyblock/vixy`:
      return VixyBlock
    case `acf/topco-content-section`:
      return ContentAcfBlock
    case `cgb/block-topco-block`:
      return ContentBlock
    default:
      return null
  }
}

export default GetCustomBlock
