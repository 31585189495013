import css from "@emotion/css"
import styled from "@emotion/styled"
import { Button, Content, Control, Field, FieldBody, Input, Subtitle } from "bloomer"
import React from "react"

const StyledInput = styled(Input)`
  border-radius: 0;
  line-height: 50px;
  height: 50px;
`

export const Newsletter = props => (
  <div className={`has-background-primary`}>
    <Content
      css={css`
        padding: 3rem;
      `}
    >
      <Subtitle isSize={5}>
        Stay informed on new top models joining
        <br />
        our VIP AGENCY!
      </Subtitle>
      <Field>
        <FieldBody>
          <Field isGrouped>
            <Control isExpanded>
              <StyledInput placeholder="Name" />
            </Control>
          </Field>
          <Field>
            <Control>
              <StyledInput placeholder="Email" />
            </Control>
          </Field>
          <Field>
            <Control>
              <Button
                isFullWidth
                css={css`
                  margin-top: 0 !important;
                `}
                className="has-background-black is-inline wp-block-button"
              >
                Submit
              </Button>
            </Control>
          </Field>
        </FieldBody>
      </Field>
    </Content>
  </div>
)
