import debug from "debug"
import PropTypes from "prop-types"
import React from "react"
import { THEMES } from "../config/theme"
import Layout from "../layouts"
import CategoryTemplate from "./category-template.component"
import NewsTemplate from "./news-template.component"
import { isCategoryTemplate } from "./page-category-fields"
import PageContent from "./page-content.component"

export const parseTemplate = filename => (filename ? filename.replace(`.php`, ``) : ``)

const PageTemplate = props => {
  const { page, latestPost } = props.data
  const { acf, title, name, template } = page
  debug(`PageTemplate`)(props)
  const templateName = parseTemplate(template)
  const { page_theme, content_sections } = acf
  const theme = THEMES[page_theme] || THEMES.default
  const isHome = page.wordpress_id === 4
  return (
    <Layout
      isHome={isHome}
      templateName={`template-page`}
      title={unescape(title || name)}
      acf={
        latestPost
          ? {
              ...acf,
              latest_news_slider_images: page.wordpress_id === 115 && [
                latestPost.edges[0].node.featured_media,
              ],
              page_sloped: `right`,
              page_sloped_amount: 30,
            }
          : acf
      }
      themeName={theme.name}
    >
      {page.wordpress_id === 115 && <NewsTemplate {...props}></NewsTemplate>}
      {isCategoryTemplate(templateName) ? (
        <CategoryTemplate {...props}></CategoryTemplate>
      ) : (
        (content_sections || page.block) && (
          <PageContent content_sections={content_sections} blocks={page.blocks} />
        )
      )}
    </Layout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PageTemplate
