import css from "@emotion/css"
import { Section } from "bloomer"
import debug from "debug"
import PropTypes from "prop-types"
import React from "react"

import { PageIntro } from "../atoms/page-intro.component"
import { THEMES } from "../config/theme"
import { INTRO_POSITION } from "../consts/acf.constants"
import PageCategoryFields from "./page-category-fields"
import PageContent from "./page-content.component"

export const parseTemplate = filename => (filename ? filename.replace(`.php`, ``) : ``)

const CategoryTemplate = props => {
  const { page } = props.data
  const { acf, blocks, title } = page
  debug(`CategoryTemplate`)(props)
  const {
    page_intro_transparency,
    page_intro_show,
    page_intro_button_text,
    page_intro_button_link,
    page_intro_text,
    content_sections,
    page_theme,
    page_intro_theme,
  } = acf
  const theme = THEMES[page_theme] || THEMES.default
  const introTheme = THEMES[page_intro_theme] || theme
  return (
    <>
      {page_intro_show !== INTRO_POSITION.HIDDEN && (
        <PageIntro
          css={css`
            z-index: 4;
            position: relative;
            margin-bottom: 2rem;
          `}
          className={`${page_intro_show} page-intro theme-${introTheme.name}`}
          title={title}
          text={page_intro_text}
          button_link={page_intro_button_link}
          button_text={page_intro_button_text}
          transparency={page_intro_transparency}
          theme_style={introTheme.name}
          offset={-50}
        />
      )}
      <div
        css={css`
          background-color: ${theme.backgroundColor};
          color: ${theme.color};
          h1,
          h2,
          h3 {
            color: ${theme.color};
          }
          margin-bottom: 3rem;
        `}
      >
        {content_sections && (
          <Section isPaddingless className={`theme-${page_theme}`}>
            <PageContent content_sections={content_sections} blocks={blocks} />
          </Section>
        )}
        <PageCategoryFields {...props} />
      </div>
    </>
  )
}
CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CategoryTemplate
